/* — Avatar — */
.qo-Avatar {
    @apply flex items-center place-content-center shrink-0 bg-qo-light rounded-full h-50px w-50px;

    img {
        @apply rounded-full object-cover h-full w-full;
    }

    .mat-icon {
        @apply m-0 #{!important};
    }

    &.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
        right: -3px;
        top: -3px;
    }
}

.qo-AvatarBadge {
    avatar {
        @apply block;
    }

    .mat-badge-content {
        --mat-badge-container-overlap-offset: -16px;
    }
}

.qo-PosterBadge {
    &.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
        --mat-badge-container-overlap-offset: 0 0 -18px -18px;
    }
}

.qo-IconButtonBadge {
    &.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
        right: -7px;
        top: -7px;
    }
}

/* — Poster — */
.qo-Poster {
    @apply relative;

    img {
        @apply block
    }
}

/* — Help message — */
.qo-HelpMessage {
    @apply flex items-center rounded-md p-2 max-w-xl bg-qo-light/50 dark:bg-qo-light/30;
}

.qo-HelpMessage-icon {
    @apply mr-2 self-start;
}

.qo-HelpMessage-content {
    p:last-child {
        @apply mb-0;
    }
}

.qo-Spinner {
    @apply relative;

    &.qo-SpinnerColor-full {
        .qo-Spinner-valueContent {
            @apply text-qo-full dark:text-qo-dark-full;
         }
        .mat-mdc-progress-spinner circle {
            @apply stroke-qo-full dark:stroke-qo-dark-full #{!important};
        }
    }

    &.qo-SpinnerColor-few { 
        .qo-Spinner-valueContent {
            @apply text-qo-few dark:text-qo-dark-few;
        }
        .mat-mdc-progress-spinner circle {
            @apply stroke-qo-few dark:stroke-qo-dark-few #{!important};
        }
    }

    &.qo-SpinnerColor-some {
        .qo-Spinner-valueContent {
            @apply text-qo-some dark:text-qo-dark-some;
        }
        .mat-mdc-progress-spinner circle {
            @apply stroke-qo-some dark:stroke-qo-dark-some #{!important};
        }
    }

    &.qo-SpinnerColor-over {
        .qo-Spinner-valueContent {
            @apply text-qo-over dark:text-qo-dark-over;
        }
        .mat-mdc-progress-spinner circle {
            @apply stroke-qo-over dark:stroke-qo-dark-over #{!important};
        }
    }
}

/* — Spinner — */
.qo-Spinner-value {
    @apply absolute left-3px top-3px flex items-center justify-center rounded-full 
    text-sm border-2 border-qo-accent/20 p-1.5;
    height: calc(100% - 6px);
    width: calc(100% - 6px);

    span {
        @apply break-all;
    }

    .qo-Spinner-valueContent {
        @apply overflow-hidden text-ellipsis whitespace-nowrap;
    }
}

.qo-LoadingSpinner {
    @apply flex relative h-150px w-150px;

    .mat-mdc-progress-spinner {
        @apply absolute left-0 top-0;
    }
}

/* — Video — */
.qo-VideoIframe { 
    iframe {
        @apply aspect-video w-full rounded-lg border-none;
    }
}

/* — Document icons — */

.qo-DocIcon {
    @apply inline-flex bg-black text-white font-bold text-[10px]/none uppercase overflow-hidden before:m-auto before:content-[attr(data-type)];

    &[data-type=zip],
    &[data-type=rar] {
        background: #acacac;
    }
    &[data-type^=doc] {
        background: #307cf1;
    }
    &[data-type^=xls] {
        background: #0f9d58;
    }
    &[data-type^=ppt] {
        background: #d24726;
    }
    &[data-type=pdf] {
        background: #e13d34;
    }
    &[data-type=txt] {
        background: #5eb533;
    }
    &[data-type=mp3],
    &[data-type=wma],
    &[data-type=m4a],
    &[data-type=flac] {
        background: #8e44ad;
    }
    &[data-type=mp4],
    &[data-type=wmv],
    &[data-type=mov],
    &[data-type=avi],
    &[data-type=mkv] {
        background: #7a3ce7;
    }
    &[data-type=bmp],
    &[data-type=jpg],
    &[data-type=jpeg],
    &[data-type=gif],
    &[data-type=png] {
        background: #f4b400;
    }
}