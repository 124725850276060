@media print {

    @page {
        /*size: 330mm 427mm;
        margin: 14mm;*/
    }

    body {
        width: auto!important;
        margin: auto!important;
        font-family: serif;
        font-size: 12pt;
        background-color: #fff!important;
        color: #000!important;
    }

    p, h1, h2, h3, h4, h5, h6, blockquote, ul, ol {
        color: #000!important;
    }

    p, blockquote {
        orphans: 3; 
        widows: 3; 
    }

    blockquote, ul, ol {
        page-break-inside: avoid; 
    }
        
    h1 {
        page-break-before: always; 
    }

    h1, h2, h3, caption {
        page-break-after: avoid; 
    }

    a:not(.qo-no-print-href) {
        color: #000!important;
        text-decoration: underline!important;
    }
        
    a[href]:after:not(.qo-no-print-href) {
        content: " (" attr(href) ")"; 
    }

    header,
    footer,
    nav,
    a.mat-mdc-button-base,
    button:not(.qo-Avatar):not(.qo-Day-button):not(.qo-Status),
    .qo-no-print,
    .qo-ContentHeader-actions,
    .qo-ContentHeaderBar,
    .mat-mdc-paginator,
    .mat-mdc-progress-bar,
    .timeline-group .mat-mdc-checkbox,
    .mat-sidenav,
    .mat-mdc-tooltip,
    .qo-NavArea {
        display: none !important;
    }

    .qo-HomePersonalSpace {
        background: none !important;
    }

    .mat-sidenav-content {
        width: 100% !important;
    }

    .max-w-qo {
        max-width: none !important;
    }
}