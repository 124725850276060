button {
    @apply font-display;
}

.mat-mdc-icon-button {
    @apply w-10 h-10 p-2 leading-none #{!important};
}

.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button {
    @apply whitespace-normal leading-tight px-4 min-h-[2.5rem] h-auto #{!important};

    .mat-mdc-progress-spinner {
        @apply ml-1.5 -mr-1;
    }

    .mdc-button__label {
        @apply flex items-center text-left flex-1 justify-center;
    }
}

.mat-mdc-unelevated-button:not([disabled]) {
    .mat-mdc-progress-spinner {
        circle {
            @apply stroke-white;
        }
    }
}

.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-mini-fab,
.mat-mdc-raised-button {
    @apply rounded-full py-2 #{!important};

    &.qo-tonal.mat-primary:not([disabled]) {
        @apply bg-qo-primary200 text-qo-primary200-contrast dark:bg-qo-primary50 dark:text-qo-primary50-contrast #{!important};
    }

    &.qo-tonal.mat-accent:not([disabled]) {
        @apply bg-qo-accent200 text-qo-accent200-contrast dark:bg-qo-accent50 dark:text-qo-accent50-contrast #{!important};
    }
}

.mat-mdc-unelevated-button,
.mat-mdc-mini-fab,
.mat-mdc-raised-button {
    //Angular 15 issue (auto contrast)
    &.mat-primary:not([disabled]) {
        @apply text-qo-primary-contrast dark:text-qo-primary300-contrast #{!important};
    }
    &.mat-accent:not([disabled]) {
        @apply text-qo-accent-contrast dark:text-qo-accent300-contrast #{!important};
    }
}

.mat-mdc-outlined-button {
    @apply rounded-full py-[7px] #{!important};
}

.mat-mdc-raised-button:not([disabled=true]) {
    @apply shadow-button rounded-full shadow-qo-primary/40 dark:shadow-qo-primary300/30; 

    &:active {
        @apply shadow-button-active shadow-qo-primary/60 dark:shadow-qo-primary300/50 #{!important};
    }
}

.qo-big-button {
    @apply whitespace-normal rounded-full md:text-lg md:py-0.5 md:px-5 h-12 #{!important};

    &.mat-mdc-raised-button:not([disabled=true]) {
        @apply shadow-big-button shadow-qo-primary/30 dark:shadow-qo-primary300/20;

        &:active {
            @apply shadow-big-button-active shadow-qo-primary/50 dark:shadow-qo-primary300/40 #{!important};
        }
    }
}

.qo-BulkActions-buttons {
    button {
        @apply shrink-0 mx-0.5 sm:mx-1 last:mr-0 #{!important};
    }

    .mat-mdc-outlined-button,
    .mat-mdc-unelevated-button {
        @apply min-w-0 rounded-full w-10 h-10 md:h-9 md:w-auto border-0 sm:border px-1.5 md:px-4 #{!important};

        .mat-icon {
            @apply mx-0 md:-ml-1 md:mr-2 text-2xl/none h-6 w-6 md:text-lg/none md:h-[1.125rem] md:w-[1.125rem] #{!important};
        }
    }

    .mdc-button__label {
        @apply flex-none md:flex-1 #{!important};
    }

    .mat-divider {
        @apply h-5 mx-0.5 sm:ml-1 opacity-80 #{!important};
    }
}

.qo-MatButtonToggleGroupCustom {
    .mat-button-toggle-label-content {
        @apply flex items-center leading-none p-0 #{!important};
    }

    .mat-button-toggle-focus-overlay {
        @apply border-none #{!important};
    }

    .mat-button-toggle {
        button {
            @apply bg-qo-neutral-old dark:bg-qo-dark-800 rounded-md border-none py-2.5 px-3.5 #{!important};
        }
    }

    .mat-button-toggle-checked {
        button {
            @apply text-qo-primary dark:text-qo-primary200 border-solid border-2 border-qo-primary dark:border-qo-primary200 py-2 px-3 #{!important};
        }
    }
}