.mat-mdc-form-field {
    @apply mb-1.5;

    [matSuffix] {
        @apply text-black/50 dark:text-white/50 #{!important};
    }

    &.qo-readonly {
        @apply pointer-events-none;
    }

    &.qo-with-overflow {
        .mdc-text-field {
            @apply overflow-visible #{!important};
        }

        .mat-mdc-form-field-icon-suffix {
            @apply self-end #{!important};
        }
    }
}

.mat-mdc-optgroup-label {
    .mdc-list-item__primary-text {
        @apply text-sm opacity-60 #{!important};
    }
}

// For tailwindcss
.mdc-notched-outline__notch {
    @apply border-x-0 #{!important};
}

form,
.qo-form {
    .mat-mdc-checkbox,
    .mat-mdc-radio-group {
        @apply inline-block -mt-1 -ml-[11px];

        .mdc-form-field {
            @apply items-start #{!important};
        }
    }

    .mat-mdc-checkbox,
    .mat-mdc-radio-button {
        label {
            @apply pl-0 whitespace-normal cursor-pointer pt-[9px] pb-1 #{!important};
        }
    }
}

.mdc-checkbox__checkmark,
.mdc-checkbox__mixedmark {
    @apply text-qo-primary-contrast dark:text-qo-primary300-contrast border-qo-primary-contrast dark:border-qo-primary300-contrast #{!important};
}

.qo-DateTimeInput {
  @apply -mx-2;
    .mat-mdc-icon-button {
        @apply h-7 w-7 p-1 leading-5 #{!important};

        svg {
            height: 18px !important;
            width: 18px !important;
        }
    }

    .mat-mdc-select-arrow-wrapper {
        @apply transform-none ml-1 #{!important};
    }
}

.mat-date-range-input-end-wrapper {
    height: 18px !important;//fix bad alignment in Firefox
}

.qo-PhoneInput {
    .mat-mdc-select-arrow-wrapper {
        transform: translateY(0%) !important;
    }
}

.scrollable-panel {
    .mat-mdc-option {
        touch-action: unset!important;
        user-select: unset!important;// fix scroll on mat-select with tooltip on mat-option
    }
}

.qo-sticky-submit {
    @apply sticky bottom-0 z-900 #{!important};
}

.qo-has-main-nav {
    .qo-sticky-submit {
        @apply bottom-14 md:bottom-0 #{!important};
    }
}

.mat-mdc-slide-toggle {
    label {
        @apply pl-2 cursor-pointer #{!important};
    }
}

.qo-SlideToggleMultiLine {
    @apply block h-auto leading-normal min-h-[24px] #{!important};

    button {
        @apply mt-1 #{!important};
    }

    .mdc-form-field {
        @apply items-start #{!important};
    }
}

.mat-calendar-body-in-range:before {
    @apply bg-qo-primary/20 #{!important};
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    @apply bg-qo-primary/30 #{!important};
}


.qo-without-hint {
    .mat-mdc-form-field-subscript-wrapper {
        @apply hidden;
    }
}

.qo-FilterFormField {

    &.mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) label {
        @apply cursor-pointer;
    }

    input {
        @apply text-sm #{!important};
    }

    .mat-mdc-form-field-infix {
        @apply py-2.5 min-h-0 w-auto #{!important};
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
        @apply top-[21px] text-sm #{!important};
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
        @apply -translate-y-[28px] scale-75 #{!important};
    }

    .mat-mdc-form-field-subscript-wrapper {
         @apply hidden #{!important};
    }

    .qo-FilterFormField-trigger {
        @apply flex items-baseline text-sm font-medium;

        span + span {
            @apply bg-qo-contrast text-[0.8em] rounded text-white block mx-1 py-0.5 px-1;
        }
    }

    .mat-mdc-form-field-icon-prefix>.mat-icon,
    .mat-mdc-form-field-icon-suffix>.mat-icon {
        @apply p-2 #{!important};
    }

    &.has-selected-options {
        &:not(.mat-focused) {
            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
                @apply border-black dark:border-white;
            }
        }
    }
}

.qo-FilterFormField-option {
    .mdc-list-item__primary-text {
        @apply text-sm #{!important};
    }
}


.qo-with-subtitle {
    .mdc-list-item__primary-text {
        line-height: 1.2;
    }
}

.qo-FilterFormField-optionSubtitle {
    display: block;
    font-size: 12px;
    font-style: italic;
    opacity: .8;
}

.qo-Fsubmit {
    @apply bg-white dark:bg-qo-dark-700 rounded-t mt-auto overflow-hidden text-left;

    .qo-Fsubmit-inner {
        padding: 4px 0;
    }

    &.qo-sticky {
        @apply bottom-0 sticky z-800;
    }

    .mat-mdc-form-field-error {
        font-size: 14px;
        margin: 0 0 8px;
    }

    button {
        margin: 4px 16px 4px 0;
    }
}

.qo-has-main-nav {
    .qo-Fsubmit {
        &.qo-sticky {
            @apply bottom-14 md:bottom-0;
        }
    }
}

/* — Password Strength meter — */
.qo-StrengthMeterBar {
    --mdc-linear-progress-track-height: 8px;

    .mdc-linear-progress__buffer-bar {
        @apply bg-transparent border-solid border border-black/20 dark:border-white/20 rounded-full #{!important};
    }

    &.qo-ProgressColor-veryWeak,
    &.qo-ProgressColor-weak {
        .mdc-linear-progress__bar-inner {
            @apply border-qo-warn dark:border-qo-dark-warn #{!important};
        }
    }

    &.qo-ProgressColor-poor {
        .mdc-linear-progress__bar-inner {
            @apply border-qo-poor dark:border-qo-dark-poor #{!important};
        }
    }

    &.qo-ProgressColor-strong,
    &.qo-ProgressColor-veryStrong {
        .mdc-linear-progress__bar-inner {
            @apply border-qo-success dark:border-qo-dark-success #{!important};
        }
    }
}

.qo-TeammateForm {
    .mdc-linear-progress__buffer-bar {
        @apply bg-qo-primary50
    }
}

/* — Location Auto Complete — */
.qo-LocationAutoComplete {
    @apply max-h-72 #{!important};

    .mdc-list-item__primary-text {
        @apply overflow-hidden #{!important};
    }
}


/* — Calendar multi select — */
.qo-CalendarMultiSelect {
    .mat-calendar-body-label {
        @apply invisible;
    }

    .mat-calendar-body-cell:not(.mat-calendar-body-disabled) {
        .mat-calendar-body-cell-content {
            @apply border border-black/30 dark:border-white/30 ;
        }
        .mat-calendar-body-selected {
            @apply border-0;
        }
    }
}

.qo-CalendarMonthSlot {
  .mat-calendar-body-label {
      @apply invisible p-0 #{!important};
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled) {
      .mat-calendar-body-cell-content {
          @apply border border-black/30 dark:border-white/30;
      }
      .mat-calendar-body-selected {
          @apply border-0;
      }
  }

  .mat-calendar-header {
      @apply hidden;
  }

  &.qo-read {
      .mat-calendar-body-cell {
          @apply cursor-default #{!important};
      }

      .mat-calendar-body-cell-content {
          @apply border-none;
      }

      .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
      .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
      .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
          @apply bg-transparent #{!important};
      }
  }
}

/* — Form editor — */
.qo-QuestionInput-readonly {
    .mat-mdc-form-field label {
         @apply pointer-events-none #{!important};
    }
}
