@import url('https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap');

////////////////////////////////////////////////////////////////////
// QO
////////////////////////////////////////////////////////////////////

$qo-theme-typography-config: mat.m2-define-typography-config(
    $font-family: 'DM Sans, sans-serif',
    $headline-5: mat.m2-define-typography-level(48px, 1.3, 500),
    $headline-6: mat.m2-define-typography-level(36px, 1.3, 500),
    $subtitle-1: mat.m2-define-typography-level(24px, 1.3, 500),
    $body-1: mat.m2-define-typography-level(16px, 1.5, 400),
    $body-2: mat.m2-define-typography-level(16px, 1.5, 400),
    $button: mat.m2-define-typography-level(16px, 1.5, 500)
);

$qo-theme-primary: (
    50 : var(--MAT_PRIMARY_50),
    100: var(--MAT_PRIMARY_50),
    200 : var(--MAT_PRIMARY_200),
    300 : var(--MAT_PRIMARY_300),
    400 : var(--MAT_PRIMARY_300),
    500 : var(--MAT_PRIMARY_500),
    600 : var(--MAT_PRIMARY_500),
    700 : var(--MAT_PRIMARY_500),
    800 : var(--MAT_PRIMARY_900),
    900 : var(--MAT_PRIMARY_900),
    contrast: (
        50 : var(--MAT_PRIMARY_50_CONTRAST),
        200 : var(--MAT_PRIMARY_200_CONTRAST),
        300 : var(--MAT_PRIMARY_300_CONTRAST),
        500 : var(--MAT_PRIMARY_500_CONTRAST),
        900 : var(--MAT_PRIMARY_900_CONTRAST),
    )
);

$qo-theme-accent: (
    50 : var(--MAT_ACCENT_50),
    100: var(--MAT_ACCENT_50),
    200 : var(--MAT_ACCENT_200),
    300 : var(--MAT_ACCENT_300),
    400 : var(--MAT_ACCENT_300),
    500 : var(--MAT_ACCENT_500),
    600 : var(--MAT_ACCENT_500),
    700 : var(--MAT_PRIMARY_500),
    800 : var(--MAT_PRIMARY_500),
    900 : var(--MAT_ACCENT_900),
    contrast: (
        50 : var(--MAT_ACCENT_50_CONTRAST),
        200 : var(--MAT_ACCENT_200_CONTRAST),
        300 : var(--MAT_ACCENT_300_CONTRAST),
        500 : var(--MAT_ACCENT_500_CONTRAST),
        900 : var(--MAT_ACCENT_900_CONTRAST),
    )
);



$qo-theme: mat.m2-define-light-theme((
    color: (
        primary: mat.m2-define-palette($qo-theme-primary, 500, 200),
        accent: mat.m2-define-palette($qo-theme-accent, 500, 200),
        warn: mat.m2-define-palette(mat.$m2-red-palette, 700)
    ),
    typography: $qo-theme-typography-config,
));

$qo-theme-dark: mat.m2-define-dark-theme((
    color: (
        primary: mat.m2-define-palette($qo-theme-primary, 300, 50),
        accent: mat.m2-define-palette($qo-theme-accent, 300, 50),
        warn: mat.m2-define-palette(mat.$m2-red-palette, 200)
    ),
    typography: $qo-theme-typography-config,
));

@include mat.all-component-themes($qo-theme);
@include mat.all-component-typographies($qo-theme);

.dark {
    color-scheme: dark; 
    @include mat.all-component-colors($qo-theme-dark);
}

.qo-PrimaryText {
    @apply text-qo-primary dark:text-qo-primary300 #{!important};
}

.qo-AccentText {
    @apply text-qo-accent dark:text-qo-accent300 #{!important};
}

.qo-PrimaryBg {
    @apply bg-qo-primary text-qo-primary-contrast dark:bg-qo-primary300 dark:text-qo-primary300-contrast #{!important};
}

.qo-AccentBg {
     @apply bg-qo-accent text-qo-accent-contrast dark:bg-qo-accent300 dark:text-qo-accent300-contrast #{!important};
}

.qo-PrimaryTonalBg {
    @apply bg-qo-primary200 text-qo-primary200-contrast dark:bg-qo-primary50 dark:text-qo-primary50-contrast #{!important};
}

.qo-AccentTonalBg {
     @apply bg-qo-accent200 text-qo-accent200-contrast dark:bg-qo-accent50 dark:text-qo-accent50-contrast #{!important};
}

:root {
	--mat-dialog-container-max-width: 100vw;
	--mat-dialog-container-small-max-width: 100vw;
    
}

.light {
    body {
        --mat-app-primary: var(--MAT_PRIMARY_500);
        --mat-app-on-primary: var(--MAT_PRIMARY_500_CONTRAST);
        --mdc-theme-error: #d32f2f;
    }
}

.dark {
    body {
        --mat-app-primary: var(--MAT_PRIMARY_300);
        --mat-app-on-primary: var(--MAT_PRIMARY_300_CONTRAST);
        --mdc-switch-selected-handle-color: var(--mat-app-primary);
        --mdc-theme-error: #ef9a9a;
    }
}

.mat-mdc-slide-toggle.mat-primary {
    --mdc-switch-selected-handle-color: var(--mat-app-primary);
    --mdc-switch-selected-hover-state-layer-color: var(--mat-app-primary);
    --mdc-switch-selected-pressed-state-layer-color: var(--mat-app-primary);

    --mdc-switch-selected-focus-handle-color: var(--MAT_PRIMARY_900);
    --mdc-switch-selected-hover-handle-color: var(--MAT_PRIMARY_900);
    --mdc-switch-selected-pressed-handle-color: var(--MAT_PRIMARY_900);
    --mdc-switch-selected-focus-track-color: var(--MAT_PRIMARY_300);
    --mdc-switch-selected-hover-track-color: var(--MAT_PRIMARY_300);
    --mdc-switch-selected-pressed-track-color: var(--MAT_PRIMARY_300);
    --mdc-switch-selected-track-color: var(--MAT_PRIMARY_300);
}

.dark {
    .mat-mdc-slide-toggle.mat-primary {
        --mdc-switch-selected-focus-state-layer-color:  var(--mat-app-primary);
        --mdc-switch-selected-focus-handle-color: var(--MAT_PRIMARY_200);
        --mdc-switch-selected-hover-handle-color: var(--MAT_PRIMARY_200);
        --mdc-switch-selected-pressed-handle-color: var(--MAT_PRIMARY_200);
        --mdc-switch-selected-focus-track-color: var(--MAT_PRIMARY_500);
        --mdc-switch-selected-hover-track-color: var(--MAT_PRIMARY_500);
        --mdc-switch-selected-pressed-track-color: var(--MAT_PRIMARY_500);
        --mdc-switch-selected-track-color: var(--MAT_PRIMARY_500);
    }
}


.mat-mdc-form-field-error {
    color: var(--mdc-theme-error, #d32f2f);
}