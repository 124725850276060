.qo-animation-hide {
    animation: .5s hide 2s ease-out forwards;
}

@keyframes highlightLine {
    0% { 
       @apply bg-qo-primary/70;
    }
    100% {
       @apply bg-transparent;
    }
}

@keyframes highlightShift {
    0% { 
        @apply bg-white/90;
    }
    100% {
        @apply bg-transparent;
    }
}

@keyframes hide {
    0% { 
        @apply opacity-100;
    }
    50% { 
        @apply opacity-0;
    }
    100% {
        @apply opacity-0 h-0 min-h-0 p-0 m-0;
    }
}