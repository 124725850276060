@function toRGB ($color) {
    @return red($color) + " " + green($color) + " " + blue($color);
}

.qo-ApplicationTheme-red {
    --themePrimary-50: #ffebee;
    --themePrimary: #f44336;
    --themePrimaryContrast: white;
    --themePrimary-dark: #ef9a9a;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 211 47 47;
    --themePrimaryRGB: 244 67 54;
}

.qo-ApplicationTheme-pink {
    --themePrimary-50: #fce4ec;
    --themePrimary: #e91e63;
    --themePrimaryContrast: white;
    --themePrimary-dark: #f48fb1;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 216 27 96;
    --themePrimaryRGB: 233 30 99;
}

.qo-ApplicationTheme-purple {
    --themePrimary-50: #f3e5f5;
    --themePrimary: #9c27b0;
    --themePrimaryContrast: white;
    --themePrimary-dark: #ce93d8;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 142 36 170;
    --themePrimaryRGB: 156 39 176;
}

.qo-ApplicationTheme-deep-purple {
    --themePrimary-50: #ede7f6;
    --themePrimary: #673ab7;
    --themePrimaryContrast: white;
    --themePrimary-dark: #b39ddb;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 94 53 177;
    --themePrimaryRGB: 103 58 183;
}

.qo-ApplicationTheme-indigo {
    --themePrimary-50: #e8eaf6;
    --themePrimary: #3f51b5;
    --themePrimaryContrast: white;
    --themePrimary-dark: #9fa8da;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 57 73 171;
    --themePrimaryRGB: 63 81 181;
}

.qo-ApplicationTheme-blue {
    --themePrimary-50: #e3f2fd;
    --themePrimary: #1e88e5;
    --themePrimaryContrast: white;
    --themePrimary-dark: #90caf9;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 25 118 210;
    --themePrimaryRGB: 30 136 229;
}

.qo-ApplicationTheme-light-blue {
    --themePrimary-50: #e1f5fe;
    --themePrimary: #039be5;
    --themePrimaryContrast: white;
    --themePrimary-dark: #4fc3f7;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 2 136 209;
    --themePrimaryRGB: 3 155 229;
}

.qo-ApplicationTheme-cyan {
    --themePrimary-50: #e0f7fa;
    --themePrimary: #0097a7;
    --themePrimaryContrast: white;
    --themePrimary-dark: #4dd0e1;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 0 151 167;
    --themePrimaryRGB: 0 151 167;
}

.qo-ApplicationTheme-teal {
    --themePrimary-50: #e0f2f1;
    --themePrimary: #009688;
    --themePrimaryContrast: white;
    --themePrimary-dark: #80cbc4;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 0 121 107;
    --themePrimaryRGB: 0 150 136;
}

.qo-ApplicationTheme-green {
    --themePrimary-50: #e8f5e9;
    --themePrimary: #43a047;
    --themePrimaryContrast: white;
    --themePrimary-dark: #a5d6a7;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 56 142 60;
    --themePrimaryRGB: 67 160 71;
}

.qo-ApplicationTheme-light-green {
    --themePrimary-50: #f1f8e9;
    --themePrimary: #689f38;
    --themePrimaryContrast: white;
    --themePrimary-dark: #aed581;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 104 159 56;
    --themePrimaryRGB: 104 159 56;
}

.qo-ApplicationTheme-lime {
    --themePrimary-50: #f9fbe7;
    --themePrimary: #9e9d24;
    --themePrimaryContrast: white;
    --themePrimary-dark: #cddc39;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 192 202 51;
    --themePrimaryRGB: 158 157 36;
}

.qo-ApplicationTheme-amber {
    --themePrimary-50: #fff8e1;
    --themePrimary: #ff6f00;
    --themePrimaryContrast: rgba(0, 0, 0, .87);
    --themePrimary-dark: #ffca28;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 255 179 0;
    --themePrimaryRGB: 255 111 0;
}

.qo-ApplicationTheme-orange {
    --themePrimary-50: #fff3e0;
    --themePrimary: #ef6c00;
    --themePrimaryContrast: white;
    --themePrimary-dark: #ffb74d;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 251 140 0;
    --themePrimaryRGB: 239 108 0;
}

.qo-ApplicationTheme-deep-orange {
    --themePrimary-50: #fbe9e7;
    --themePrimary: #f4511e;
    --themePrimaryContrast: white;
    --themePrimary-dark: #ffab91;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 230 74 25;
    --themePrimaryRGB: 244 81 30;
}

.qo-ApplicationTheme-brown {
    --themePrimary-50: #efebe9;
    --themePrimary: #795548;
    --themePrimaryContrast: white;
    --themePrimary-dark: #bcaaa4;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 109 76 65;
    --themePrimaryRGB: 121 85 72;
}

.qo-ApplicationTheme-grey {
    --themePrimary-50: #fafafa;
    --themePrimary: #757575;
    --themePrimaryContrast: white;
    --themePrimary-dark: #bdbdbd;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 117 117 117;
    --themePrimaryRGB: 117 117 117;
}

.qo-ApplicationTheme-blue-grey {
    --themePrimary-50: #eceff1;
    --themePrimary: #607d8b;
    --themePrimaryContrast: white;
    --themePrimary-dark: #b0bec5;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 84 110 122;
    --themePrimaryRGB: 96 125 139;
}

.qo-ApplicationTheme-black {
    --themePrimary-50: #fafafa;
    --themePrimary: #212121;
    --themePrimaryContrast: white;
    --themePrimary-dark: #bdbdbd;
    --themePrimaryContrast-dark: rgba(0, 0, 0, .87);
    --themePrimaryBackgroundRGB: 33 33 33;
    --themePrimaryRGB: 33 33 33;
}

.qo-ApplicationTheme-primary {
    --themePrimary-50: var(--MAT_PRIMARY_50);
    --themePrimary: var(--MAT_PRIMARY_500);
    --themePrimaryContrast: var(--MAT_PRIMARY_500_CONTRAST);
    --themePrimary-dark: var(--MAT_PRIMARY_300);
    --themePrimaryContrast-dark: var(--MAT_PRIMARY_300_CONTRAST);

    --themePrimaryBackgroundRGB: var(--PRIMARY_900);
    --themePrimaryRGB: var(--PRIMARY_500);
}

.dark {
    .qo-ApplicationTheme-red {
        --themePrimaryRGB: 239 154 154;
    }

    .qo-ApplicationTheme-pink {
        --themePrimaryRGB: 244 143 177;
    }

    .qo-ApplicationTheme-purple {
        --themePrimaryRGB: 206 147 216;
    }

    .qo-ApplicationTheme-deep-purple {
        --themePrimaryRGB: 179 157 219;
    }

    .qo-ApplicationTheme-indigo {
        --themePrimaryRGB: 159 168 218;
    }

    .qo-ApplicationTheme-blue {
        --themePrimaryRGB: 144 202 249;
    }

    .qo-ApplicationTheme-light-blue {
        --themePrimaryRGB: 79 195 247;
    }

    .qo-ApplicationTheme-cyan {
        --themePrimaryRGB: 77 208 225;
    }

    .qo-ApplicationTheme-teal {
        --themePrimaryRGB: 128 203 196;
    }

    .qo-ApplicationTheme-green {
        --themePrimaryRGB: 165 214 167;
    }

    .qo-ApplicationTheme-light-green {
        --themePrimaryRGB: 174 213 129;
    }

    .qo-ApplicationTheme-lime {
        --themePrimaryRGB: 205 220 57;
    }

    .qo-ApplicationTheme-amber {
        --themePrimaryRGB: 255 202 40;
    }

    .qo-ApplicationTheme-orange {
        --themePrimaryRGB: 255 183 77;
    }

    .qo-ApplicationTheme-deep-orange {
        --themePrimaryRGB: 255 171 145;
    }

    .qo-ApplicationTheme-brown {
        --themePrimaryRGB: 188 170 164;
    }

    .qo-ApplicationTheme-grey {
        --themePrimaryRGB: 189 189 189;
    }

    .qo-ApplicationTheme-blue-grey {
        --themePrimaryRGB: 176 190 197;
    }

    .qo-ApplicationTheme-black {
        --themePrimaryRGB: 189 189 189;
    }

    .qo-ApplicationTheme-primary {
        --themePrimaryRGB: var(--PRIMARY_300);
    }
}


$qo-ApplicationThemePrimary: (
    50 : var(--themePrimary-50),
    100 : var(--themePrimary-50),
    200 : var(--themePrimary-dark),
    500 : var(--themePrimary),
    700 : var(--themePrimary),
    contrast: (
        200 : var(--themePrimaryContrast-dark),
        500 : var(--themePrimaryContrast),
    )
);
$qo-ApplicationThemeColor: mat.m2-define-palette($qo-ApplicationThemePrimary, 500, 200);
$qo-ApplicationThemeColor-dark: mat.m2-define-palette($qo-ApplicationThemePrimary, 200, 50);

$qo-ApplicationTheme: mat.m2-define-light-theme((
    color: (
        primary: $qo-ApplicationThemeColor,
        accent: $qo-ApplicationThemeColor,
        warn: mat.m2-define-palette(mat.$m2-red-palette, 500)
    ),
));

$qo-ApplicationThemeDark: mat.m2-define-dark-theme((
    color: (
        primary: $qo-ApplicationThemeColor-dark,
        accent: $qo-ApplicationThemeColor,
        warn: mat.m2-define-palette(mat.$m2-red-palette, 300)
    ),
));

.qo-ThemeButton {
    background-color: rgb(var(--themePrimaryBackgroundRGB)) !important;
}

.qo-ApplicationTheme {
    .qo-ApplicationTheme-elements {
        @include mat.button-color($qo-ApplicationTheme);
        @include mat.form-field-color($qo-ApplicationTheme);
        @include mat.checkbox-color($qo-ApplicationTheme);
        @include mat.datepicker-color($qo-ApplicationTheme);
        @include mat.input-color($qo-ApplicationTheme);
        @include mat.progress-bar-color($qo-ApplicationTheme);
        @include mat.progress-spinner-color($qo-ApplicationTheme);
        @include mat.radio-color($qo-ApplicationTheme);
        @include mat.select-color($qo-ApplicationTheme);
    }

    h1, h2, h3, h4 {
        color: rgb(var(--themePrimaryRGB)) !important;
    }

    .mat-mdc-raised-button:not([disabled=true]) {
        box-shadow: 0 1px 3px rgb(var(--themePrimaryRGB) / .4) !important;

        &:active {
            box-shadow: 0 2px 6px rgb(var(--themePrimaryRGB) / .6) !important;
        }
    }

    .qo-CalendarMultiSelect {
        .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
        .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
            background: rgb(var(--themePrimaryRGB) / .3) !important;
        }
    }

    .mat-mdc-progress-bar {
        .mdc-linear-progress__buffer-bar {
            background: rgb(var(--themePrimaryRGB) / .3) !important;
        }
    }
}

.dark {
    .qo-ApplicationTheme {
        .qo-ApplicationTheme-elements {
            @include mat.button-color($qo-ApplicationThemeDark);
            @include mat.form-field-color($qo-ApplicationThemeDark);
            @include mat.checkbox-color($qo-ApplicationThemeDark);
            @include mat.datepicker-color($qo-ApplicationThemeDark);
            @include mat.input-color($qo-ApplicationThemeDark);
            @include mat.progress-bar-color($qo-ApplicationThemeDark);
            @include mat.progress-spinner-color($qo-ApplicationThemeDark);
            @include mat.radio-color($qo-ApplicationThemeDark);
            @include mat.select-color($qo-ApplicationThemeDark);
        }

        .mat-mdc-progress-bar {
            .mdc-linear-progress__buffer-bar {
                background: rgb(var(--themePrimaryRGB) / .3) !important;
            }
        }
    }
}
