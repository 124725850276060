a {
    @apply no-underline;
}

a:not([class]),
.qo-default-link,
.qo-primary-link {
    @apply text-inherit;

    &:active {
        @apply underline;
    }
}

.qo-primary-link {
    @extend .qo-PrimaryText;
}

strong {
    @apply font-medium;
}

.qo-h1 {
    @apply font-bold text-3xl/tight md:text-4xl+/tight #{!important};
}

.qo-tiny-h1 {
    @apply font-bold text-2xl/tight md:text-3xl/tight mb-3 #{!important};
}

.qo-h2 {
    @apply font-bold text-2xl/tight md:text-3xl/tight mb-4 md:mb-6 #{!important};
}

.is-side-panel,
.mdc-dialog__content {
    h2,
    .qo-h2 {
        @apply text-xl md:text-2xl leading-tight md:leading-tight #{!important};
    }

    h3,
    .qo-h3 {
        @apply text-lg md:text-xl leading-tight md:leading-tight #{!important};
    }
}

.qo-h3 {
    @apply font-medium text-lg/tight sm:text-xl/tight md:text-2xl/tight #{!important};
}

.qo-break-word {
    word-break: break-word;
}

.qo-line-clamp { 
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.line-clamp-2,
.line-clamp-3 {
    line-height: 1.2;
}

.qo-ContentHtml {
    a {
        @apply text-inherit font-medium underline hover:no-underline;
    }
}

.qo-ApplicationForm-h2,
.qo-ApplicationForm-content h2 {
    @apply text-22 #{!important};
}

.qo-ApplicationForm-h3,
.qo-ApplicationForm-content h3 {
    @apply text-xl #{!important};
}

.qo-ApplicationForm-h4,
.qo-ApplicationForm-content h4 {
    @apply text-lg font-medium #{!important};
}
