.cdk-overlay-container {
    .mat-mdc-dialog-surface {
        @apply rounded-xl dark:bg-qo-dark-700 dark:text-white #{!important};
    }
}

.mat-mdc-dialog-title {
    @apply text-xl font-bold mb-0 #{!important};
}

.mdc-dialog__content {
    @apply py-2 text-qo-text/87 dark:text-white/87 max-h-[75vh] #{!important};

    .qo-Fsubmit.qo-sticky,
    .mat-mdc-table + .qo-Paginator  {
        @apply -bottom-2 #{!important};
    }
}

.mdc-dialog__actions {
    @apply px-6 pb-4 #{!important};
}

.mat-mdc-tooltip {
    @apply text-sm #{!important};
}

.mat-mdc-snack-bar-container {
    @apply bg-black text-white;

    @include media-breakpoint('lt-md') {
        margin-bottom: calc(var(--footer-size) + 8px);
    }

    button {
        @apply text-qo-primary uppercase;
    }
}

.cdk-global-scrollblock {
    .qo-FSubmit {
        z-index: 980 !important;
    }
}

.qo-PersistentMobileNav {
    .qo-DialogFullScreen {
        padding: 0 0 var(--footer-size);
    }
}

/*.cdk-overlay-pane {
    &:not(.qo-DialogFullScreen) {
        max-width: 98% !important;   
    }
}*/

.qo-DialogFullScreen {
    .mat-mdc-dialog-container {
        @apply border-none rounded-none shadow-none m-0 p-0 w-full #{!important};
    } 

    .cdk-overlay-container & {
        .mat-mdc-dialog-surface {
            @apply rounded-none #{!important};
        }
    }
}

.qo-DialogFullScreen-close {
    @apply flex pt-3 pr-3;

    button {
        @apply ml-auto;
    }
}

.qo-Popover {
    @apply bg-white dark:bg-qo-dark-800 rounded-md p-4 #{!important};
}

.qo-Popover-actions {
    @apply flex items-center justify-end -mt-2 mb-2 #{!important};

    button,a {
        @apply ml-px #{!important};
    }
}

.qo-ConstraintsPopover {
    @apply w-98p min-w-300px sm:w-112 #{!important};
    
    h3 {
        @apply flex items-center #{!important};

        .mat-icon {
            @apply h-auto text-4xl+ w-10 #{!important};
        }
    } 
}

.qo-ConstraintsPopover-violations {
    @apply overflow-auto max-h-56 #{!important};

    p {
        @apply text-sm last:mb-4 #{!important};
    }
}