.mat-mdc-chip {
    @apply h-auto #{!important};
}

.qo-Tags {
    @apply flex items-center flex-wrap -m-1 #{!important};
}

.qo-NoWrap .qo-Tags {
    @apply flex-nowrap #{!important};
}

.qo-Tag {
    @apply bg-gray-700 text-white dark:bg-qo-neutral-old dark:text-qo-text/90 rounded-md text-sm m-1 py-1 px-2 cursor-default shrink-0 #{!important};
}

.qo-ActivitiesChips {
    @apply outline-none #{!important};

    .mat-mdc-chip {
        @apply bg-qo-neutral-old dark:text-qo-text/90 rounded-md text-sm font-medium py-1 px-2 #{!important};
    }

    .mat-mdc-chip-disabled {
        @apply opacity-100 #{!important};
    }
}