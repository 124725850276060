/* — Shifts — */
.qo-ColorTextForSpinner {
    &.qo-SpinnerColor-full {
        @apply text-qo-full;
    }

    &.qo-SpinnerColor-few { 
        @apply text-qo-few;
    }

    &.qo-SpinnerColor-some {
        @apply text-qo-some;
    }

    &.qo-SpinnerColor-over {
        @apply text-qo-over;
    }
}

/* — Licenses — */
.qo-CouponAccordion {
    .mat-expansion-indicator { 
        @apply origin-center #{!important};  
        
        &::after {
            @apply border-qo-light -mt-1.5 #{!important}; 
        }
    }

    .mat-expansion-panel-body {
        @apply pb-3 pl-2 pr-0;
    }
}

/* — Planning — */
.mat-calendar {
    .has-shifts {
        .mat-calendar-body-cell-content:after {
            @apply rounded-full h-1 w-1 absolute content-[""] bg-qo-contrast bottom-3px;
        }
    }
}

/* — Map — */
.gm-style {
    .gm-style-iw-d::-webkit-scrollbar-track,
    .gm-style-iw-d::-webkit-scrollbar-track-piece,
    .gm-style-iw-c,
    .gm-style-iw-t::after {
        @apply dark:bg-none dark:bg-qo-dark-700 #{!important};
    }

    .gm-style-iw-t::after {
        @apply dark:shadow-md;
    }

    .gm-style-iw-tc::after {
        @apply dark:bg-qo-dark-700 #{!important};
    }

    .gm-style-iw button.gm-ui-hover-effect {
        @apply h-5 w-5 #{!important};

        &>span {
            @apply dark:invert m-0 h-5 w-5 #{!important};
        }  
    }
}  

/* — Messaging — */
.qo-Message-content {
    .qo-VideoIframe { 
        @apply xl:w-[30vw] 2xl:w-[34vw] 3xl:w-[38vw] max-w-[600px];
    }
}

/* — Activities — */
activity-details-general-information,
shift-details {
    .qo-VideoIframe {
        @apply max-w-2xl;
    }
}

.qo-TeammateAssignment-description {
    .qo-VideoIframe {
        @apply max-w-3xl;
    }
}