img {
    @apply max-w-full;
}

.mat-icon {
    @apply shrink-0;
}



// HACK: https://github.com/angular/components/issues/14280
.cdk-global-scrollblock {
    position: initial !important;
    @apply overflow-hidden #{!important};
}

.qo-scrollbar {
    overflow-y: auto;
    overflow-x: hidden;

    @include media-breakpoint('gt-sm') {
        scrollbar-color: rgba(0, 0, 0, .3);
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            height: 6px;
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: rgba(0, 0, 0, .3);
        }
    }
}

.dark .qo-scrollbar,
.qo-scrollbar.qo-light {
    @include media-breakpoint('gt-sm') {
        scrollbar-color: rgba(255, 255, 255, .3);

        &::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, .3);
        }
    }
}

.qo-invisible-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}

image-cropper {
    img {
        @apply inline;
    }
}

mat-sidenav-container,
mat-sidenav-content {
    overflow: initial !important;
}

zxing-scanner {
    video {
        @apply h-full #{!important};
    }
}

.qo-QrcodeCp {
    svg {
        @apply block mx-auto my-6;
    }
}

/* — Drag — */

.cdk-drag-preview {
    @apply box-border shadow-selection #{!important};
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging {
    @apply cursor-move #{!important};

    * {
        @apply cursor-move #{!important};
    }
}

.qo-MealDetails {
    .cdk-drag-placeholder {
        * {
            @apply bg-qo-neutral-old dark:bg-qo-dark-600 text-qo-neutral-old dark:text-qo-dark-600 #{!important};
        }
    }

    .cdk-drag-preview {
        @apply shadow-none #{!important};
    }
}

.qo-OrganizationTeammateField {
    .cdk-drag-placeholder {
        * {
            @apply bg-qo-neutral-old dark:bg-qo-dark-600 text-qo-neutral-old dark:text-qo-dark-600 #{!important};
        }
    }
}

.qo-DataLoadingProgressBar {
  .mdc-linear-progress__buffer-bar{
    @apply bg-qo-primary50;
  }
}

/* — Emoji — */

.emoji-mart {
    @apply block #{!important};
    
    svg {
        @apply inline #{!important};
    }
}

.emoji-mart-scroll {
    @apply h-52 #{!important};
}

/* — Stripes — */

.qo-UnavailableStripes {
    background-image: linear-gradient(45deg, #d1d5db 36.36%, #ffffff 36.36%, #ffffff 50%, #d1d5db 50%, #d1d5db 86.36%, #ffffff 86.36%, #ffffff 100%);
    background-size: 15.56px 15.56px;
}

.qo-UnavailableAndAssignedStripes {
    background-image: linear-gradient(45deg, red 36.36%, #ffffff 36.36%, #ffffff 50%, red 50%, red 86.36%, #ffffff 86.36%, #ffffff 100%);
    background-size: 15.56px 15.56px;
}

.dark {
    .qo-UnavailableStripes {
        background-image: linear-gradient(45deg, #666666 36.36%, #303030 36.36%, #303030 50%, #666666 50%, #666666 86.36%, #303030 86.36%, #303030 100%);
        background-size: 15.56px 15.56px;
    }
}

/* — Virtual Scroll — */

.cdk-virtual-scroll-content-wrapper {
    @apply w-full overflow-hidden;
}

//fix tailwind / material
.mat-expansion-indicator::after {
    border-color: rgba(0, 0, 0, 0.54) !important;
}
