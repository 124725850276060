// Media step breakpoint mixin based on Angular Material lib
$breakpoints: (
    xs: 'screen and (max-width: 599px)',
    sm: 'screen and (min-width: 600px) and (max-width: 959px)',
    md: 'screen and (min-width: 960px) and (max-width: 1279px)',
    lg: 'screen and (min-width: 1280px) and (max-width: 1919px)',
    lt-sm: 'screen and (max-width: 599px)',
    lt-md: 'screen and (max-width: 959px)',
    lt-lg: 'screen and (max-width: 1279px)',
    gt-xs: 'screen and (min-width: 600px)',
    gt-sm: 'screen and (min-width: 960px)',
    gt-md: 'screen and (min-width: 1280px)',
    gt-lgs: 'screen and (min-width: 1600px)'
) !default;

$grid-breakpoints: (
    xs: 0,
    sm: 600px,
    md: 960px,
    lg: 1280px,
    lgs: 1500px
) !default;

@mixin media-breakpoint($breakpointName) {

    $mediaQuery: map-get($breakpoints, $breakpointName);

    @if ($mediaQuery != null) {

        @media #{$mediaQuery} {
            @content
        }
    }
}