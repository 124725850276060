.qo-Export-buttons {
    @apply relative grid gap-4 grid-cols-auto-160px #{!important};

    button {
        @apply flex flex-col items-center rounded-2xl border border-qo-light cursor-pointer min-h-25 overflow-hidden p-4 transition-all duration-200 hover:bg-gray-50 dark:hover:bg-white/10 #{!important};
    }
}

.qo-Export-icoBtn {
    img {
        @apply w-14 mb-3 -mr-2 #{!important};
    }

    span {
        @apply text-sm italic #{!important};
    }
}

.qo-Export-spinner {
    @apply bg-white/80 dark:bg-qo-dark-700/80 flex absolute top-0 left-0 h-full w-full #{!important};

    .mat-mdc-progress-spinner {
        @apply m-auto #{!important};
    }
}