main-layout {
    @apply flex flex-col min-h-full #{!important};
}

.mat-toolbar-row {
    @apply px-3 #{!important};
}

.qo-TabBar {
    @apply text-center whitespace-nowrap py-4 px-6 mb-8 overflow-auto -mx-4 md:mx-0 border-b border-qo-light dark:border-qo-light/50 #{!important};
}

.qo-TabBar-link {
    @apply inline-flex items-center cursor-pointer font-medium rounded-full px-4 opacity-100 mx-4 h-12 hover:bg-gray-100 hover:dark:bg-qo-dark-600 transition-all whitespace-nowrap #{!important};

    &.qo-current {
        @apply bg-qo-primary/30 dark:bg-qo-primary/50 pointer-events-none #{!important};
    }
}

.qo-TabLine {
    @apply flex whitespace-nowrap overflow-auto gap-3 pb-3;
}

.qo-TabLine-link {
    @apply px-3 py-1.5 no-underline rounded-lg+ cursor-pointer;

    &:not(.qo-current) {
        @apply hover:bg-black/10 dark:hover:bg-white/10 transition-colors;
    } 

    &.qo-current {
        @apply relative pointer-events-none font-medium bg-qo-primary200 dark:bg-qo-primary50 text-qo-primary200-contrast dark:text-qo-primary50-contrast after:absolute after:content-[''] after:h-1 after:-bottom-3 after:w-1/2 after:left-1/2 after:-translate-x-1/2 after:bg-qo-primary200 dark:after:bg-qo-primary50 after:rounded-full;
    }
}

.qo-TabDividor {
    @apply bg-qo-neutral/30 dark:bg-qo-dark-800 rounded-full w-full h-1 -mt-1 mb-4;
}

.qo-MainNav {
    .qo-NavButton {
        @apply flex justify-start items-start w-10 h-10 rounded-xl text-left leading-5 p-2 overflow-hidden no-underline group-hover:w-full group-hover:h-auto group-[.qo-open]:w-full group-[.qo-open]:h-auto hover:bg-white/10 font-normal opacity-80 transition-all #{!important};

        &.qo-current {
            @apply bg-qo-primary/60 dark:bg-qo-primary/50 font-medium opacity-100 #{!important};
        }

        .qo-NavButton-text {
            @apply ml-2 mt-[3px] hidden overflow-hidden group-hover:block group-hover:w-52 group-[.qo-open]:block group-[.qo-open]:w-52 shrink-0 #{!important};
        }
    }
}

.cdk-global-scrollblock {
    .qo-MainMobileNav {
        @apply z-990 #{!important};
    }
}

.qo-PersistentMobileNav {
    .qo-MainMobileNav {
        @apply z-2000 #{!important};
    }
}

.qo-MainNav.qo-open ~ .qo-Content {
    .qo-BulkActions {
        @apply xl:left-70 #{!important};
    }
}

app-footer {
    @apply mt-auto;
}

.qo-ErrorMsg {
    @apply rounded-md font-medium text-qo-warn dark:text-qo-dark-warn bg-qo-warn/10 dark:bg-qo-dark-warn/10 border border-qo-warn dark:border-qo-dark-warn py-2 px-3;
}


.mat-mdc-menu-item .mat-icon {
    margin-top: -3px;
}

.mat-mdc-menu-panel {
    @apply bg-white dark:bg-qo-dark-600;

    .qo-current {
        @apply text-qo-primary font-medium;
    }
}

.mat-mdc-menu-content {
    & > em {
        @apply block text-sm font-medium not-italic opacity-40 uppercase pt-2 pb-1 px-4 min-w-150px;
    }
}

.mat-mdc-list-base .mat-mdc-list-item {
    @apply text-base;
}

.qo-FooterImages {
    @apply flex overflow-hidden;
}

.qo-FooterImages-list {
    flex: 1;
    min-width: 50%;
    opacity: .6;
    position: relative;

    @include media-breakpoint('gt-xs') {
        min-width: calc(100% / 3);
    }

    @include media-breakpoint('gt-sm') {
        min-width: 25%;
    }

    @include media-breakpoint('gt-md') {
        min-width: 20%;
    }

    img {
        display: block;
        height: 100%;
        max-height: 350px;
        object-fit: cover;
        width: 100%;
    }

    span {
        background: rgba(0, 0, 0, .8);
        border-radius: 10px 0 0 0;
        bottom: 0;
        color: #fff;
        display: inline-block;
        font-size: 12px;
        max-width: 80%;
        overflow: hidden;
        padding: 3px 8px;
        position: absolute;
        text-overflow: ellipsis;
        right: 0;
        white-space: nowrap;
    }
}

.mat-expansion-panel {
    @apply dark:bg-qo-dark-700 #{!important}; 

    &.mat-expansion-panel-spacing {
        @apply m-0 #{!important}; 
    }

    .mat-expanded {
        .mat-expansion-panel-header-title {
            @apply font-medium;
        }
    }
}

/* — Divider — */
.qo-Divider {
    @apply h-0 block border-t border-qo-neutral dark:border-white/20;
}

.qo-VerticalDivider {
    @apply w-0 border-l border-qo-neutral dark:border-white/20;
}

/* — Icons — */
.material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}

.material-symbols-outlined.qo-fill {
    font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}

.material-symbols-outlined.qo-wght200 {
    font-variation-settings:
    'FILL' 0,
    'wght' 200,
    'GRAD' 0,
    'opsz' 24
}

.material-symbols-outlined.qo-wght200.qo-fill {
    font-variation-settings:
    'FILL' 1,
    'wght' 200,
    'GRAD' 0,
    'opsz' 24
}